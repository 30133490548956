<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16 flexV">
        <el-button type="primary" size="small" :disabled="!btn.add" icon="el-icon-plus" @click="addNew()">新增</el-button>
        <el-button type="danger" size="small" :disabled="!btn.delete" icon="el-icon-delete" @click="doDelete()">删除</el-button>
        <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()" icon="el-icon-refresh-right">刷新</el-button>
        <el-form class="vg_ml_8" :inline="true" :model="otherForm" size="small" label-width="100px">
          <el-form-item label="客户货号:">
            <el-input v-model="otherForm.prod_cust_no" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="中文品名">
            <el-input v-model="otherForm.mtrb_name" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button class="vg_ml_8" type="primary" @click="getMacosList">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <search-table
        ref="multiTable"
        :data="tableData"
        :tableRowKey="'maco_id'"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        @select="selectRows"
        @selection-change="handleSelectionChange"
        @select-all="selectRows"
        @row-dblclick="dbClickJp"
        :need-fixed-height="true"
        @getTableData="getMacosList"
      >
        <template v-slot:mesa_status="scope">
          <div style="text-align: center">
            <el-tag :type="getStatusName(scope.row.mesa_status).type" size="mini">
              {{ getStatusName(scope.row.mesa_status).name }}
            </el-tag>
          </div>
        </template>
        <template v-slot:order_status="scope">
          <div style="text-align: center">
            <el-tag :type="getStatusName(scope.row.order_status).type" size="mini">
              {{ getStatusName(scope.row.order_status).name }}
            </el-tag>
          </div>
        </template>
        <template v-slot:status="scope">
          <div style="text-align: center">
            <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">
              {{ helper.getStatusName(scope.row.status).name }}
            </el-tag>
          </div>
        </template>
      </search-table>
    </el-card>
  </div>
</template>

<script>
import { macoAPI } from '@api/modules/maco';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { changeBusinessDept, changePurchaseDept, tableProperties } from '@/views/SalesManagement/MacoManage/maco';
import { getArrayIds } from '@assets/js/arrayUtils';
import { getDept } from '@api/public';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'MacoList',
  components: {
    SearchTable
  },
  data() {
    return {
      otherForm: {},
      tableProperties: tableProperties,
      tableData: [],
      totalPage: 0,
      btn: {},
      loadFlag: true,
      multiSelection: [],
      currentPage: 1
    };
  },
  watch: {
    $route(to, from) {
      if (from.path === '/maco_add' || from.path === '/maco_edit') {
        this.initData();
      }
    }
  },
  async mounted() {
    await this.getMacosList();
    // 模块部门字段disabled判断
    // this.$store.commit('setBusinessDept', await getDept('业务', 'ids'));
    // this.$store.commit('setDomesticTradeDept', await getDept('内贸', 'ids'));
    // this.$store.commit('setCommerceDept', await getDept('电商', 'ids'));
    // this.$store.commit('setPurchaseDept', await getDept('采购', 'ids'));
    // this.$store.commit('setAccessoriesDept', await getDept('辅料', 'ids'));
  },
  methods: {
    initData() {
      this.getMacosList();
    },
    async getMacosList() {
      this.loadFlag = false;
      await macoAPI.getMacos(Object.assign(this.$refs.multiTable.searchForm, this.otherForm)).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        this.btn = data.btn;
        this.loadFlag = false;
      });
    },
    // 刷新
    buttonRefresh() {
      this.otherForm = {};
      this.$refs.multiTable.resetFields();
      this.getMacosList();
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    // 判断是否可以删除
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      this.jump('/maco_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: this.$route.query.perm_id,
            form_id: row.maco_id,
            cust_order_no: row.cust_order_no
          })
        )
      });
    },
    addNew() {
      this.jump(`/maco_add?perm_id=${this.$route.query.perm_id}`);
    },
    // 未选中提示
    doDelete() {
      if (this.multiSelection.length === 0) return this.$message.warning('请至少选择一条数据！');
      if (this.multiSelection.some(({ status }) => status === 2)) {
        this.$refs.multiTable.clearSelection();
        return this.$message.warning('此单据已生效或者正在审批中,无法删除！');
      }
      this.mBox();
    },
    //客户信息list删除提示
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          macoAPI.deleteMacoByIds({ maco_ids: getArrayIds(this.multiSelection, 'maco_id').toString() }).then(({ data }) => {
            this.$message.success('删除成功!');
            this.initData();
          });
        })
        .catch(err => {
          console.error(err);
          this.$message.info('取消删除');
        });
    },
    getStatusName(status) {
      switch (status) {
        case 0:
          return { name: '未报价', type: 'info' };
        case 1:
          return { name: '报价中', type: 'warning' };
        case 2:
          return { name: '已报价', type: 'success' };
      }
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
</style>
